$white-color: white;
$font-weight-bold: bolder;
$font-weight-light: 100;
$medium-breakpoint: 768px;
$large-breakpoint: 992px;
$desktop-h1-font-size: 40px;
$desktop-h2-font-size: 28px;
$desktop-p-font-size: 18px;
$mobile-h1-font-size: 28px;
$mobile-h2-font-size: 24px;
$mobile-p-font-size: 15px;

.home_wrapper {
  // background-image: url('/public/media/home/Prudential_bienvenida.jpg');
  height: calc(100vh - 200px);
  min-height: 576px;
  top: -25px;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: auto;

  .body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 10px 32px;
    gap: 25px;
    width: 45%;
    height: 100%;

    h1,
    h2,
    p {
      color: $white-color;
      font-weight: $font-weight-bold;
    }

    h2 {
      font-size: $desktop-h2-font-size;
    }

    h1 {
      font-size: $desktop-h1-font-size;
    }
    p {
      font-size: $desktop-p-font-size;
      font-weight: $font-weight-light;
    }
  }
  @media screen and (max-width: $medium-breakpoint) {
    .body-wrapper {
      width: 100%;
      h1 {
        font-size: $mobile-h1-font-size;
      }
      h2 {
        font-size: $mobile-h2-font-size;
      }
      p {
        font-size: $mobile-p-font-size;
      }
    }
  }

  @media screen and (max-width: $large-breakpoint) {
    top: 0;
    margin: 15px;
  }
}

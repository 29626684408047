//
// Accordion
//
input[type='checkbox']:checked + label:after {
  content: '';
  width: 12px;
  height: 6px;
  border: 4px solid #0fbf12;
  float: left;
  margin-left: -1.95em;
  border-right: 0;
  border-top: 0;
  margin-top: 1em;
  transform: rotate(-55deg);
}

input[type='radio']:checked + label:before,
input[type='checkbox']:checked + label:before {
  border-color: #0fbf12;
}

input[type='radio']:checked:disabled + label,
input[type='checkbox']:checked:disabled + label {
  background: #ccc;
  color: #fff !important;
}

input[type='radio']:checked:disabled + label:before,
input[type='checkbox']:checked:disabled + label:before {
  border-color: #bdbdbd;
}
// Base
.accordion {
  // According heading
  .accordion-header {
    cursor: pointer;
  }

  // Icon toggle mode
  &.accordion-icon-toggle {
    // Accordion icon expaned mode
    .accordion-icon {
      display: flex;
      flex-shrink: 0;
      transition: $transition-base;
      transform: rotate(90deg);
      align-items: center;
      justify-content: center;

      i,
      .svg-icon {
        color: var(--#{$prefix}primary);
      }
    }

    // Accordion icon collapsed mode
    .collapsed {
      .accordion-icon {
        transition: $transition-base;
        transform: rotate(0);

        i,
        .svg-icon {
          color: var(--#{$prefix}text-muted);
        }
      }
    }
  }

  // Reset accordion item border
  &.accordion-borderless {
    // According item
    .accordion-item {
      border: 0;
    }
  }

  // Reset accordion item border, border radiues and background color
  &.accordion-flush {
    // According item
    .accordion-item {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

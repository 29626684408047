//
// Stepper Pills
//

// Base
.stepper.stepper-pills {
  --#{$prefix}stepper-pills-size: 40px;
  --#{$prefix}stepper-icon-border-radius: 9px;
  --#{$prefix}stepper-icon-check-size: 1rem;

  --#{$prefix}stepper-icon-bg-color: var(--#{$prefix}primary-light);
  --#{$prefix}stepper-icon-bg-color-current: var(--#{$prefix}primary);
  --#{$prefix}stepper-icon-bg-color-completed: var(--#{$prefix}primary-light);

  --#{$prefix}stepper-icon-border: 0;
  --#{$prefix}stepper-icon-border-current: 0;
  --#{$prefix}stepper-icon-border-completed: 0;

  --#{$prefix}stepper-icon-number-color: var(--#{$prefix}primary);
  --#{$prefix}stepper-icon-number-color-current: var(--#{$prefix}white);

  --#{$prefix}stepper-icon-check-color-completed: var(--#{$prefix}primary);

  --#{$prefix}stepper-label-title-opacity: 1;
  --#{$prefix}stepper-label-title-opacity-current: 1;
  --#{$prefix}stepper-label-title-opacity-completed: 1;

  --#{$prefix}stepper-label-title-color: var(--#{$prefix}gray-800);
  --#{$prefix}stepper-label-title-color-current: var(--#{$prefix}gray-600);
  --#{$prefix}stepper-label-title-color-completed: var(--#{$prefix}text-muted);

  --#{$prefix}stepper-label-desc-opacity: 1;
  --#{$prefix}stepper-label-desc-opacity-current: 1;
  --#{$prefix}stepper-label-desc-opacity-completed: 1;

  --#{$prefix}stepper-label-desc-color: var(--#{$prefix}text-muted);
  --#{$prefix}stepper-label-desc-color-current: var(--#{$prefix}gray-400);
  --#{$prefix}stepper-label-desc-color-completed: var(--#{$prefix}gray-400);

  --#{$prefix}stepper-line-border: 1px dashed var(--#{$prefix}gray-300);

  // Nav
  .stepper-nav {
    display: flex;
  }

  // Item
  .stepper-item {
    display: flex;
    align-items: center;
    transition: $transition-link;

    // Icon
    .stepper-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: $transition-link;
      width: var(--#{$prefix}stepper-pills-size);
      height: var(--#{$prefix}stepper-pills-size);
      border-radius: var(--#{$prefix}stepper-icon-border-radius);
      background-color: #0227303d;
      border: var(--#{$prefix}stepper-icon-border);
      margin-right: 1.5rem;

      .stepper-check {
        display: none;
        font-size: var(--#{$prefix}stepper-icon-check-size);
      }

      .stepper-number {
        font-weight: $font-weight-bold;
        color: #022730;
        font-size: 1.25rem;
      }
    }

    // Label
    .stepper-label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .stepper-title {
        color: var(--#{$prefix}stepper-label-title-color);
        opacity: var(--#{$prefix}stepper-label-title-opacity);
        font-weight: 600;
        font-size: 1.25rem;
        margin-bottom: 0.3rem;
      }

      .stepper-desc {
        opacity: var(--#{$prefix}stepper-label-desc-opacity);
        color: var(--#{$prefix}stepper-label-desc-color);
      }
    }

    // Current
    &.current {
      transition: $transition-link;

      .stepper-icon {
        transition: $transition-link;
        background-color: #022730;
        border: var(--#{$prefix}stepper-icon-border-current);

        .stepper-check {
          display: none;
        }

        .stepper-number {
          color: var(--#{$prefix}stepper-icon-number-color-current);
          font-size: 1.35rem;
        }
      }

      .stepper-label {
        .stepper-title {
          opacity: var(--#{$prefix}stepper-label-title-opacity-current);
          color: var(--#{$prefix}stepper-label-title-color-current);
        }

        .stepper-desc {
          opacity: var(--#{$prefix}stepper-label-desc-opacity-current);
          color: var(--#{$prefix}stepper-label-desc-color-current);
        }
      }
    }

    // Completed
    &.current.mark-completed:last-child,
    &.completed {
      .stepper-icon {
        transition: $transition-link;
        background-color: #0227303d;
        border: var(--#{$prefix}stepper-icon-border-completed);

        .stepper-check {
          color: #022730;
          display: inline-block;
        }

        .stepper-number {
          display: none;
        }
      }

      .stepper-label {
        .stepper-title {
          opacity: var(--#{$prefix}stepper-label-title-opacity-completed);
          color: #022730;
        }

        .stepper-desc {
          opacity: var(--#{$prefix}stepper-label-desc-opacity-completed);
          color: #022730;
        }
      }
    }
  }

  // Column
  &.stepper-column {
    // Nav
    .stepper-nav {
      flex-direction: column;
      align-items: start;
    }

    // Item
    .stepper-item {
      flex-direction: column;
      justify-content: start;
      align-items: stretch;
      padding: 0;
      margin: 0;
    }

    // Wrapper
    .stepper-wrapper {
      display: flex;
      align-items: center;
    }

    // Icon
    .stepper-icon {
      z-index: 1;
    }

    // Line
    .stepper-line {
      display: block;
      flex-grow: 1;
      margin-left: calc(var(--#{$prefix}stepper-pills-size) / 2);
      border-left: var(--#{$prefix}stepper-line-border);
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}

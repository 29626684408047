// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';

#root {
  display: contents;
}

.selectOptions {
  width: 195%;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid #bbbcc53b;
  color: #b4b6c1;
}

.selectForm {
  color: var(--bs-gray-700);
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  padding: 13px 10px;
  outline: none;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.rmsc {
  --rmsc-main: transparent !important;
  --rmsc-border: none !important;
  --rmsc-gray: #5e6278 !important;
  --rmsc-bg: #f9f9f9 !important;
  --rmsc-p: 20px !important; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.dropdown-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.autocomplete-item {
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f2f2f3;
}

.titulo {
  background-color: aquamarine;
  color: red;
}
/*left right modal*/
.modal.left_modal,
.modal.right_modal {
  position: fixed;
  z-index: 99999;
}
.modal.left_modal .modal-dialog,
.modal.right_modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 25%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal-dialog {
  /* max-width: 100%; */
  margin: 1.75rem auto;
}
@media (min-width: 576px) {
  .left_modal .modal-dialog {
    max-width: 100%;
  }

  .right_modal .modal-dialog {
    max-width: 100%;
  }
}
.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
  /*overflow-y: auto;
    overflow-x: hidden;*/
  height: 100vh !important;
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*.modal.left_modal  {
    pointer-events: none;
    background: transparent;
}*/

/*Left*/
.modal.left_modal.fade .modal-dialog {
  left: -50%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog {
  left: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/*Right*/
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.5);
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header.left_modal,
.modal-header.right_modal {
  padding: 10px 15px;
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.modal_outer .modal-body {
  /*height:90%;*/
  overflow-y: auto;
  overflow-x: hidden;
  height: 91vh;
}

/* .form-check-input{
  background-color: black;
} */

.form-check-custom.form-check-solid .form-check-input:checked {
  background-color: #007bc3;
}
// .form-check-input:checked {
//   background-color: #8a0a21;
// }

.my-pagination ::ng-deep .ngx-pagination .current {
  background: #0047ad;
  border-radius: 5px;
}

.paginat {
  display: flex;
  justify-content: space-between;
}

.selectPagination {
  border: none;
  background-color: #f4f9ff;
  color: #728097;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.close {
  background-color: transparent;
}

.modal-content {
  border-radius: 10px;
}
.text-alert {
  color: #728097;
  font-size: 18px;
  font-weight: 400;
}

.pagination .active .page-link {
  background-color: #0066cc;
  border-color: #0066cc;
}

.page-link:hover {
  background-color: #faedef;
  border-color: #faedef;
  color: #0066cc;
}

.general {
  border-bottom: var(--bs-border-width) solid var(--bs-card-border-color);
  padding-bottom: 15px;
  padding-left: 0;
}

.notFound {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.table-user-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 0;
  color: #344054;
  margin-bottom: 0;
  padding-top: 5px;
}
.table-user-item-lastname {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  color: #98a2b3;
  padding-bottom: 5px;
}
.table-item-user {
  font-weight: 400;
  font-size: '16px';
  line-height: '20px';
  padding-left: 0;
  color: '#344054';
  margin-bottom: 0;
  padding-bottom: '5px';
  padding-top: '5px';
  border-bottom: 0;
}

.user-card a {
  color: #344054;
}

.modal-open .daterangepicker {
  z-index: 100000000;
}

.showInfoFund {
  position: absolute;
  bottom: 20px;
  left: 15px;
}

@media (min-width: 768px) {
  .updateConfigFund {
    position: absolute;
    top: 20px;
    right: 50px;
  }
}
@media (min-width: 768px) {
  .showInfoFund {
    position: absolute;
    top: 30px;
    left: 25px;
  }
}

.link:hover {
  color: #6cc3f5 !important;
}

#flexSwitch30x50:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.page-link:hover {
  background-color: #f2fafc;
}

.iconButton {
  border-radius: 100% !important;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6px;
  padding-left: 6px;
}
.iconButton:hover {
  transition: ease-in-out all 3s;
  background-color: #f9f9f9 !important;
}

.iconButton:active {
  scale: 0.95;
  background-color: #e9e9e9 !important;
}

/* Tooltip container */
.new-tooltip {
  position: relative;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.new-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  box-shadow: 1px 8px 66px -34px rgba(0, 0, 0, 0.69);
  text-align: center;
  padding: 5px 0;
  border: 1px solid rgb(161, 165, 183);

  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  z-index: 30;
}

/* Show the tooltip text when you mouse over the tooltip container */
.new-tooltip:hover .tooltiptext {
  visibility: visible;
}

//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

.dz-error-mark {
  display: none !important;
}
.dz-success-mark {
  display: none !important;
}
.dz-error-message {
  display: none !important;
}
.grecaptcha-badge {
  visibility: hidden;
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  // background-image: unset !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion {
  --bs-accordion-active-bg: white !important;
  cursor: default;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  cursor: default;
}
.accordion-item:first-of-type .accordion-button {
  cursor: default;
}
